@import '../../style/App.scss';

#project {
  margin-top: 100px;
  padding: 60px 0;
}

@media screen and (max-width: 990px) {
  #project {
    text-align: center;
  }
}